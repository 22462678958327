:root {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #c92b2e;
  --bs-pink: #e83e8c;
  --bs-red: #ff3e1d;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffab00;
  --bs-green: #71dd37;
  --bs-teal: #20c997;
  --bs-cyan: #03c3ec;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: rgba(67, 89, 113, 0.6);
  --bs-gray-dark: rgba(67, 89, 113, 0.8);
  --bs-gray-25: rgba(67, 89, 113, 0.025);
  --bs-gray-50: rgba(67, 89, 113, 0.05);
  --bs-primary: #c92b2e;
  --bs-primary-2: #3f5ac5;
  --bs-primary-3: #f4bc4c;
  --bs-secondary: #8592a3;
  --bs-success: #71dd37;
  --bs-info: #03c3ec;
  --bs-warning: #ffab00;
  --bs-danger: #ff3e1d;
  --bs-light: #fcfdfd;
  --bs-dark: #233446;
  --bs-gray: rgba(67, 89, 113, 0.1);
  --bs-primary-rgb: 105, 108, 255;
  --bs-secondary-rgb: 133, 146, 163;
  --bs-success-rgb: 113, 221, 55;
  --bs-info-rgb: 3, 195, 236;
  --bs-warning-rgb: 255, 171, 0;
  --bs-danger-rgb: 255, 62, 29;
  --bs-light-rgb: 252, 253, 253;
  --bs-dark-rgb: 35, 52, 70;
  --bs-gray-rgb: 67, 89, 113;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 67, 89, 113;
  --bs-body-color-rgb: 105, 122, 141;
  --bs-body-bg-rgb: 245, 245, 249;
  --bs-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  --bs-font-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-root-font-size: 16px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.53;
  --bs-body-color: #697a8d;
  --bs-body-bg: #f5f5f9;
  --bs-body-text-align: "left";
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(67, 89, 113, 0);
}

/* 
-----------------------------------
GLOBAL STYLES
-----------------------------------
 */

.text-white {
  color: var(--bs-white) !important;
}

.text-black {
  color: var(--bs-black) !important;
}
.container {
  padding: 0 8%;
  margin: auto;
}

.text-secondary {
  color: var(--bs-primary-3);
}
.display-block {
  display: block;
}
/* 
-----------------------------------
HEADER STYLES
-----------------------------------
 */

.navbar {
  width: 100%;
  transition: 0.3s ease;
  background: var(--bs-white);
}

.navbar.scrolled {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow);
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Desktop Menu */
.desktopMenu {
  display: flex;
}

.mobileMenu {
  display: none;
}

.navMenu {
  flex: 1;
  border: none;
  width: 625px;
}

.brandText {
  font-weight: 600 !important ;
  font-size: 30px !important;
  cursor: pointer;
  margin-top: 10px;
}

.ant-menu-horizontal {
  line-height: 30px !important;
}

.ant-menu-item {
  display: inline-block;
  white-space: nowrap;
}

/* 
-----------------------------------
FOOTER STYLES
-----------------------------------
 */

.footer {
  background-color: var(--bs-primary);
  padding: 20px 50px;
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-white) !important;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* 
-----------------------------------
HOME PAGE STYLES
-----------------------------------
 */

.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-card {
  color: white;
  border-radius: 10px;
}

.icon-container {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white; /* Ensure icon color is white */
}

.icon {
  font-size: 50px;
}

.bg-primary {
  background-color: var(--bs-primary);
}

.bg-primary-2 {
  background-color: var(--bs-primary-2);
}

.bg-primary-3 {
  background-color: var(--bs-primary-3);
}

.card-description {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-section .card-description {
  min-height: 110px;
}

/* 
-----------------------------------
STUDENT TOUR PAGE STYLES
-----------------------------------
 */

.section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.section-title {
  margin-bottom: 40px;
}
.image-container {
  width: 100%;
  border-radius: 10px;
}

.line-break {
  white-space: pre-line;
}

/* .carousel-card .card-description {
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

#exclusions .ant-card {
  height: 220px;
}
.carousel-card .ant-card {
  height: 400px;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228352/adventure-hike-people-concept-group-smiling-friends-forest-man-with-map_txmhym.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 62vh;
  display: flex;
  justify-content: start;
  align-items: center;
}

.custom-card {
  background-color: transparent !important;
  box-shadow: none !important;
}

.card-column {
  border-right: 1px solid #ddd;
}

.card-column:last-child {
  border-right: none;
}

.card-container {
  border-bottom: 1px solid #ddd;
}

.card-container:last-child {
  border-bottom: none; /* Remove bottom border from last card */
}

.carousel-container {
  position: relative;
}

.carousel-card {
  padding: 10px;
  width: 500px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border: none;
  z-index: 1;
}

.left-arrow {
  left: -40px;
}

.right-arrow {
  right: -40px;
}

/* 
-----------------------------------
SURVEY PAGE STYLES
-----------------------------------
 */

.survey-form span {
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}

/* 
-----------------------------------
GLOBAL ANTD OVERRIDE STYLES
-----------------------------------
 */

.ant-carousel .slick-slide {
  padding: 0 10px;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--bs-black) !important;
}

.ant-btn {
  margin-top: 20px;
}

h3.ant-typography,
h2.ant-typography {
  font-weight: 400;
}

.ant-checkbox-group {
  display: block;
}

.ant-form-item .ant-form-item-label > label {
  font-weight: 300 !important;
  font-size: 18px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }
  .mobileMenu {
    display: block;
  }

  .hamburgerIcon {
    color: var(--bs-black-rgb);
    background: none;
    border: none;
    margin-top: 0px;
  }

  .navMenu {
    width: 0;
  }

  .carousel-card .ant-card {
    height: 400px; /* Ensure uniform card height */
  }
  .card-column {
    border-right: none;
  }
  .left-arrow {
    left: -25px;
  }

  .right-arrow {
    right: -25px;
  }
  h1.ant-typography {
    font-size: 40px;
  }
  h2.ant-typography {
    font-size: 30px;
  }

  h3.ant-typography {
    font-size: 20px;
  }
  .ant-layout-header {
    padding: 0;
  }
  .container {
    padding: 0 5%;
  }
}
